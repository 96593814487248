<template lang="pug">
  div
    base-snackbar(
      :errors="accountCodePostErrors"
    )
    v-row
      v-col(cols="12")
        v-autocomplete(
          label="Parent Code"
          v-model="parentCode"
          :items="parentCodeList"
          :loading="parentCodeGetting"
          item-value="id"
          item-text="description"
          :error-messages="accountCodePostErrors.parent"
          return-object
        )
          template(v-slot:selection="{ item }")
            span {{ item.description }}
          template(v-slot:item="{ item }")
            small.grey--text {{ item.account_code }}
            span.ml-2 {{ item.description }}
      v-col(cols="12")
        v-text-field(
          label="Account Code"
          type="number"
          :readonly="!$objectEmpty(parentCode)"
          :counter="4"
          :key="accountCodeRecompute"
          v-model="accountCode"
          :error-messages="accountCodePostErrors.code"
        )
      v-col(cols="12")
        v-text-field(
          label="Description"
          v-model="description"
          :error-messages="accountCodePostErrors.description"
        )
      v-col(cols="12")
        v-btn(
          color="primary"
          block
          small
          dark
          :loading="accountCodePosting"
          @click="storeAccountCode"
        )
          span Save
</template>
<script>
import accountCodeRepository from '@/repositories/account-code.repository'
import VueRequestHandler from '@/libs/classes/VueRequestHandler.class'
import { requestVars } from '@/libs/api-helper.extra'
import searchDelay from '@/libs/searchDelay.extra'

const typing = searchDelay(500)

const [parentCodeGetVars, parentCodeGetVarNames] = requestVars({ identifier: 'parent-code' })
const [accountCodePostVars, accountCodePostVarNames] = requestVars({ identifier: 'account-code', request: 'post' })

const accountCodePostHandler = new VueRequestHandler(null, accountCodePostVarNames)
const parentCodeGetHandler = new VueRequestHandler(null, parentCodeGetVarNames)

const inputVars = () => ({
  id: null,
  parentCode: {},
  code: null,
  description: null,
})

export default {
  name: 'CreateAccountCode',
  props: {
    defaultValue: {
      type: Object,
      default: inputVars,
    },
  },
  data () {
    return {
      ...inputVars(),
      ...parentCodeGetVars,
      ...accountCodePostVars,
      accountCodeRecompute: 0,
    }
  },
  created () {
    this.getParentCodes()
  },
  computed: {
    parentId () {
      return this.parentCode.id
    },
    accountCode: {
      get () {
        const FORCE_RECOMPUTE = this.accountCodeRecompute
        if (!this.code) return null
        if (this.$objectEmpty(this.parentCode)) {
          return (this.code || '').toString().padEnd(4, '0')
        }
        return `${this.parentCode.code.toString().padEnd(2, '0')}${this.code.toString().padStart(2, '0')}`
      },
      set (newVal) {
        if (!newVal) return
        typing(() => {
          const accountCode = newVal.toString().substr(0, 2).padEnd(2, '0')
          this.code = accountCode
          this.accountCodeRecompute++
        })
      },
    },
  },
  watch: {
    defaultValue (data) {
      this.populateInputs(data)
    },
    parentCode (value) {
      if (this.$objectEmpty(value)) return
      this.changeAccountCode(value)
    },
  },
  methods: {
    getParentCodes () {
      const handler = parentCodeGetHandler
      const repository = accountCodeRepository.parent
      handler.setVue(this)
      handler.execute(repository)
    },
    storeAccountCode () {
      const handler = accountCodePostHandler
      const repository = accountCodeRepository.store
      const { data } = this.getFieldInputs()
      handler.setVue(this)
      handler.execute(repository, [data])
    },
    getFieldInputs () {
      const excluded = ['id', 'parentCode']
      const data = Object.keys(inputVars())
        .concat(['parent'])
        .filter(key => !excluded.includes(key))
        .reduce((result, key) => {
          result[key.camelToSnakeCase()] = this[key]
          return result
        }, {})
      const { id } = this
      return { id, data }
    },
    populateInputs (data) {
      Object.keys(data).forEach(key => {
        const key2 = key.snakeToCamelCase()
        this[key2] = data[key]
      })
    },
    initWebsockets () {
      const { echo } = this.$store.state.websocket
      echo.private('database.event')
        .listen('DBStoreEvent', this.dbStoreEvent)
    },
    dbStoreEvent ({ data, model }) {
      if (this.$objectEmpty(data) || !model) return
      if (model === 'AccountCode') {
        this.accountCodeDBStoreEvent(data)
      }
    },
    accountCodeDBStoreEvent (data) {
      if (!data.parent_code) {
        this.parentCodeList.push(data)
        return
      }
      this.accountCodeList.push(data)
    },
    changeAccountCode (args) {
      const { latestChild = {} } = Window.objectChangeKeyCase(args) || {}
      const { code = 0 } = latestChild
      this.code = code + 1
    },
  },
  mixins: [
    require('./mixin').default,
  ],
}
</script>